import React, { useEffect, useContext, useState } from "react";
import _ from "lodash";
import { navigate, Link } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { ref, uploadBytes } from "firebase/storage";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../../components/PageWrapper";
import { Select } from "../../components/Core";
import RichTextEditor from "../../components/RichTextEditor";
import ImageUploader from "../../components/ImageUploader";
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { validation } from "../../utils/validation";
import { getCountries } from "../../utils/getCountries";
import notify from "../../utils/notify";
import { updateAccount, getAccountImage } from "../../api";
import { storage } from "../../services/firebase";

const AdminEditClient = ({ params }) => {
  const [loading, setLoading] = useState(true);
  const { auth } = useContext(AuthContext);
  const { client, industries, getClientDetails } = useContext(DataContext);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();
  const param = params[`*`];
  const countries = getCountries();

  const fetchClientDetails = async (clientId) => {
    if (clientId) {
      await getClientDetails(clientId);
    } else {
      navigate(-1);
    }
    setLoading(false);
  };

  useEffect(() => {
    const clientId = param;
    fetchClientDetails(clientId);
    return () => setLoading(true);
  }, []);

  const onEditorStateChange = (data) => {
    setValue("description", data);
  };

  const onImageUploadChange = (data) => {
    setValue("logo", data[0].file);
  };

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      let imageUrl;
      const account_id = client.account_id;
      const industry = data.industry.label;
      const country = data.country.label;

      if (data.logo) {
        const imageRef = ref(storage, `logos/${account_id}`);
        await uploadBytes(imageRef, data.logo);
        // console.log("Logo upload successful");
        // Get logo url
        imageUrl = await getAccountImage(account_id);
      }

      const formData = _.omit(data, "country", "logo", "accountid", "industry");
      const payload = {
        account_id,
        industry,
        country,
        imageUrl: imageUrl ? imageUrl : client.imageUrl,
        status: "active",
        ...formData,
      };
      const response = await updateAccount(account_id, "Client", payload);
      if (response) {
        reset();
        notify("Profile updated successfully", "success");
        setTimeout(() => {
          navigate(-1);
        }, 500);
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="my-9">
                  {loading ? (
                    <Skeleton
                      height={50}
                      containerClassName="w-40"
                      className="w-40"
                    />
                  ) : (
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="d-flex align-items-center ml-4"
                    >
                      {" "}
                      <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                      <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                        Back
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-18">
              <div className="row mb-11 align-items-center">
                <div className="col-12 mb-lg-0 mb-4">
                  {loading ? (
                    <Skeleton
                      height={44}
                      containerClassName="w-100"
                      className="w-70"
                    />
                  ) : (
                    <h3 className="font-size-6 mb-0 text-center">
                      Update {`${client?.company}`} Profile
                    </h3>
                  )}
                </div>
              </div>

              <div className="p-8 py-sm-7 px-sm-11">
                {loading ? (
                  <div className="row justify-content-center">
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                    <Skeleton
                      height={44}
                      containerClassName="w-100 text-center"
                      className="col-lg-6 mb-8"
                    />
                  </div>
                ) : (
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="logo"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Company Logo*
                          </label>

                          {client?.imageUrl ? (
                            <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap">
                              <div>
                                <img
                                  src={client?.imageUrl}
                                  alt={`${client?.company} devshop profile`}
                                  height={200}
                                />
                              </div>
                              <div className="ml-sm-12 mt-6 mt-sm-0">
                                <Controller
                                  name="logo"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: false }}
                                  as={
                                    <ImageUploader
                                      onImageUploadChange={onImageUploadChange}
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errors?.logo?.message || ""}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Controller
                                name="logo"
                                control={control}
                                defaultValue=""
                                rules={validation.logo}
                                as={
                                  <ImageUploader
                                    onImageUploadChange={onImageUploadChange}
                                  />
                                }
                              />
                              <span className="text-danger small">
                                {errors?.logo?.message || ""}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="accountid"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Wallet ID*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="accountid"
                            name="accountid"
                            defaultValue={client?.account_id}
                            disabled
                            ref={register(validation.wallet)}
                          />
                          <span className="text-danger small">
                            {errors?.accountid?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="firstname"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            name="firstname"
                            defaultValue={client?.firstname}
                            ref={register(validation.clientFirstname)}
                          />
                          <span className="text-danger small">
                            {errors?.firstname?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="lastname"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            name="lastname"
                            defaultValue={client?.lastname}
                            ref={register(validation.clientLastname)}
                          />
                          <span className="text-danger small">
                            {errors?.lastname?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="email"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Email*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            defaultValue={client?.email}
                            ref={register(validation.email)}
                          />
                          <span className="text-danger small">
                            {errors?.email?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="company"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Company Name*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="company"
                            name="company"
                            defaultValue={client?.company}
                            ref={register(validation.company)}
                          />
                          <span className="text-danger small">
                            {errors?.company?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="description"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Company Description*
                          </label>
                          <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            rules={validation.companyDescription}
                            as={
                              <RichTextEditor
                                onEditorStateChange={onEditorStateChange}
                                initialValue={client?.description}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.description?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="website"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Website URL*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="website"
                            name="website"
                            defaultValue={client?.website}
                            ref={register(validation.website)}
                          />
                          <span className="text-danger small">
                            {errors?.website?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="linkedin"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            LinkedIn URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="linkedin"
                            name="linkedin"
                            defaultValue={client?.linkedin}
                            ref={register(validation.linkedin)}
                          />
                          <span className="text-danger small">
                            {errors?.linkedin?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="twitter"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Twitter URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="twitter"
                            name="twitter"
                            defaultValue={client?.twitter}
                            ref={register(validation.twitter)}
                          />
                          <span className="text-danger small">
                            {errors?.twitter?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="github"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Github URL
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="github"
                            name="github"
                            defaultValue={client?.github}
                            ref={register(validation.companyGithub)}
                          />
                          <span className="text-danger small">
                            {errors?.github?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="industry"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Industry*
                          </label>
                          <Controller
                            name="industry"
                            control={control}
                            defaultValue={
                              industries[
                              industries.findIndex(
                                ({ label }) => label === client?.industry
                              )
                              ] || ""
                            }
                            rules={validation.industry}
                            as={
                              <Select
                                options={industries}
                                className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                border={false}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.industry?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="stack"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Tech Stack
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="stack"
                            name="stack"
                            defaultValue={client?.stack}
                            ref={register(validation.stack)}
                          />
                          <span className="text-danger small">
                            {errors?.stack?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="address"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Location*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            defaultValue={client?.address}
                            ref={register(validation.location)}
                          />
                          <span className="text-danger small">
                            {errors?.address?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="city"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            City*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            defaultValue={client?.city}
                            ref={register(validation.city)}
                          />
                          <span className="text-danger small">
                            {errors?.city?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          <label
                            htmlFor="country"
                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                          >
                            Country*
                          </label>
                          <Controller
                            name="country"
                            control={control}
                            defaultValue={
                              countries[
                              countries.findIndex(
                                ({ label }) => label === client?.country
                              )
                              ]
                            }
                            rules={validation.country}
                            as={
                              <Select
                                options={countries}
                                className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                border={false}
                              />
                            }
                          />
                          <span className="text-danger small">
                            {errors?.country?.message || ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-medium rounded-5 text-uppercase w-100 focus-reset"
                            disabled={submitting ? true : false}
                          >
                            {submitting
                              ? "Updating profile..."
                              : "Update Profile"}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-6">
                        <div className="form-group mb-8">
                          {errors &&
                            Object.values(errors).map((field, index) => (
                              <p
                                key={index}
                                className="text-danger small m-0 p-0"
                              >
                                {field.message}
                              </p>
                            ))}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default AdminEditClient;
